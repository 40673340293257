export function debounce (func, wait, immediate) {
	let timeout, args, context, timestamp, result

	const later = function () {
		// 据上一次触发时间间隔
		const last = +new Date() - timestamp

		// 上次被包装函数被调用时间间隔last小于设定时间间隔wait
		if (last < wait && last > 0) {
			timeout = setTimeout(later, wait - last)
		} else {
			timeout = null
			// 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
			if (!immediate) {
				result = func.apply(context, args)
				if (!timeout) context = args = null
			}
		}
	}

	return function (...args) {
		context = this
		timestamp = +new Date()
		const callNow = immediate && !timeout
		// 如果延时不存在，重新设定延时
		if (!timeout) timeout = setTimeout(later, wait)
		if (callNow) {
			result = func.apply(context, args)
			context = args = null
		}
		return result
	}
}

export function str2time(str,split) {
	if (!str || str == ''){
		return ''
	}
	var d = new Date(str * 1000);    //根据时间戳生成的时间对象
	let Mon = d.getMonth() + 1;
	var date = (d.getFullYear()) + split +
		(Mon >=10?Mon:'0'+Mon) + split +
		(d.getDate()>=10?d.getDate():'0'+d.getDate()) + " " +
		(d.getHours()>=10?d.getHours():'0'+d.getHours()) + ":" +
		(d.getMinutes()>=10?d.getMinutes():'0'+d.getMinutes()) + ":" +
		(d.getSeconds()>=10?d.getSeconds():'0'+d.getSeconds());
	console.log(date)
	return date;
}
// 获取地址上的参数
export function getQueryVariable(variable){
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(false);
}

export function initmoney(value){
	var texts = [];
	let str='';
	if (value <=999) {
		str = parseFloat(value)
		texts.push(str);
	} else if (value <=999999) {
		str = value / 1000;
		str = fixed1(str)+'K'
		texts.push(str);
	} else if (value <= 999999999) {
		str = value / 1000000;

		str = fixed1(str)+'M'
		texts.push(str);
	}
	return texts[0];
}
export function fixed1(num){
	num = num.toString()
	let index = num.indexOf('.')
	if (index !== -1) {
		num = num.substring(0, 1 + index + 1)
	} else {
		num = num.substring(0)
	}
	return parseFloat(num).toFixed(1)
}
